import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import { ButtonContent } from "../global/boton/ButtonContent";
import {FiTool} from 'react-icons/fi'

function BlockHomeOne() {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <>
      <section className="w-4/5 mx-auto md:py-32 py-10">
        <div className="md:flex md:flex-row flex flex-col md:p-0 px-2 content-reverse">
          <div className="md:w-[50%] relative">
            <div
              className="w-full md:h-[600px] h-[350px] bg-cover bg-center rounded-3xl"
              style={{ backgroundImage: `url("${rpdata?.stock?.[1]}")` }}
            ></div>
            {/* <div
              className="w-[60%] h-[60%]  absolute -left-[100px] border-white border-[15px] z-10 bg-cover bg-center md:rounded-full md:-bottom-12 bottom-0 "
              style={{ backgroundImage: `url("${rpdata?.stock?.[0]}")`}}
            ></div> */}

          </div>

          <div className="md:w-[55%] z-50 rounded-2xl bloqueshaw bg-white md:-ml-20 md:mt-20 md:-mb-10 py-4 md:px-8 px-3 self-center md:text-start text-center">
            <h2 className="separator-about">We Have {rpdata?.dbPrincipal?.exprYears} Years Of Experience</h2>
            <p className="pb-4">{rpdata?.dbHome?.[0].text}</p>
            <ul className="list-inner-section pl-3 text-start">
                  <li  className="py-1 px-2 flex items-center">
                    <FiTool className="h-[20px] w-[20px] text-center" />
                    <span className="px-2 text-center">Body Work & Repairs</span>
                  </li>
                  <li  className="py-1 px-2 flex items-center">
                    <FiTool className="h-[20px] w-[20px] text-center" />
                    <span className="px-2 text-center">Painting & Refinishing</span>
                  </li>
                  <li  className="py-1 px-2 flex items-center">
                    <FiTool className="h-[20px] w-[20px] text-center" />
                    <span className="px-2 text-center">Frame Straightening</span>
                  </li>
                  <li  className="py-1 px-2 flex items-center">
                    <FiTool className="h-[20px] w-[20px] text-center" />
                    <span className="px-2 text-center">Fiber- Glass Repairs</span>
                  </li>
            </ul>
            <ButtonContent  />
            <div className="w-full">
              <span className="separator-about"></span>
            </div>
          </div>

        </div>
      </section>
    </>
  );
}

export default BlockHomeOne;
